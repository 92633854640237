<template>
  <div
    class="modal fade"
    id="trailerModal"
    tabindex="-1"
    aria-labelledby="trailerModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <iframe
          height="600"
          src="https://www.youtube.com/embed/bRbQ4OaljWs"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
